/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * Generated with the TypeScript template
 * https://github.com/react-native-community/react-native-template-typescript
 *
 * @format
 */

import React, { useEffect, useState, useRef } from 'react';
import { Platform, StatusBar } from 'react-native';
import { NavigationContainer, DefaultTheme, useNavigationContainerRef } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useDispatch, useSelector } from 'react-redux';
import Orientation from 'react-native-orientation-locker';
import SplashScreen from "react-native-splash-screen";
import 'common/src/services/localization';
import { useTranslation } from 'react-i18next';
import { resetSuccessModalAction, resetErrorModalAction, getMeAction, getNightWatchEventsAction, getNotificationsAction, logOutAction, updateVersionAction, addNotificationsAction } from './store/actions/authenticationActions';
import { biometricKeyExistsAction } from './store/biometrics/biometricsActions';
import { getPatientPrograms, getPrograms } from './store/programs/programsActions';
import { sendFbTokenAction } from './store/FB-token/fb_tokenAction';
import notificationEmitter, {NotificationTypes} from './notifEmitter/notifEmitter';
import ReactNativeBiometrics from 'react-native-biometrics';
import {NavTypes} from './navigationEmitter/navigationEmitter';
import { errorHandler } from 'common/src/services/errorService';
import { useDeviceHandler } from 'common/src/services/deviceService';
import {DateTime} from 'luxon';
import { version } from '../package.json';
import Analytics from 'appcenter-analytics';
import Crashes from 'appcenter-crashes';
import CommonModal from 'common/src/components/CommonModal';
import Tutorial from './modules/Tutorial';
import Mini_tutorial from './modules/Tutorial';
import Welcome from './modules/Welcome';
import Login from './modules/Login';
import Register from './modules/Register';
import TabNavigation from './modules/TabNavigation';
import PasswordForgotten from './modules/PasswordForgotten';
import NeurologistStep from './modules/NeurologistStep';
import Settings from './modules/Settings';
import About from './modules/About';
import My_neurologists from './modules/My_neurologists';
import AddNeurologist from './modules/AddNeurologist';
import Profile from './modules/Profile';
import PrivacyConsent from './modules/PrivacyConsent';
import Address from './modules/Address';
import InformedConsent from './modules/PrivacyConsent';
import OnboardingQuestions from './modules/OnboardingQuestions';
import SeizureVideos from './modules/SeizureVideos';
import Nightwatch from './modules/Nightwatch/Nightwatch';
import Loading from './modules/Loading';
import ChangeMyPdw from './modules/ChangeMyPwd';
import Timezone from './modules/Timezone';
import MoodSleepNotifications from './modules/MoodAndSleepNotifications';
import AddEvent from './modules/AddEvent';
import CommonStudyOverview from 'common/src/components/CommonStudyOverview';
import CommonStudySection from 'common/src/components/CommonStudySection';
import CommonStudyForm from 'common/src/components/CommonStudyForm';
import CommonStudyDisclaimer from 'common/src/components/CommonStudyDisclaimer';
import CommonStudyContacts from 'common/src/components/CommonStudyContacts';
import ReminderView from './modules/ReminderView';
import AddMedication from './modules/AddMedication/AddMedication';
import AddTreatments from './modules/AddMedication/AddTreatments';
import Drugs from './modules/AddMedication/Drugs';
import DataSharing from './modules/DataSharing';
import DataSharingSignedIn from './modules/DataSharing';
import MedicationHistory from './modules/EditMedication/MedicationHistory';
import EditMedication from './modules/EditMedication/EditMedication';
import OnboardingAddMedication from './modules/OnboardingAddMedication';
import NightwatchEvent from './modules/Nightwatch/NightwatchEvent';
import CommonProgramIntroduction from 'common/src/components/CommonProgramIntroduction';
import Reports from './modules/Reports';
import GenerateReport from './modules/GenerateReport';
import ReportGenerated from './modules/ReportGenerated';
import QualityOfLife from './modules/QualityOfLife';
import ProgramOverview from './modules/Programs/ProgramOverview';
import EducationModule from './modules/Programs/ProgramEducation';
import EducationChapter from './modules/Programs/EducationChapter';
import ProgramAction from 'common/src/components/CommonProgramActions/CommonProgramAction';
import ImagePage from 'common/src/components/CommonImagePage';
import QoLForm from './modules/QoLForm';
import MySeizuresDescription from './modules/EpilepsyDetails/SeizuresDescription/MySeizuresDescription';
import AddContact from './modules/EpilepsyDetails/SeizuresDescription/AddContact';
import MySeizureAntidote from './modules/EpilepsyDetails/SeizureAntidote/MySeizureAntidote';
import MyAppointments from './modules/EpilepsyDetails/appointments/MyAppointments';
import MyTriggersModel from './modules/EpilepsyDetails/TriggersModel/MyTriggersModel';
import AppointmentChecklist from './modules/EpilepsyDetails/appointments/AppointmentChecklist';
import MoreGraphs from './modules/MoreGraphs';
import EnableDisableBiometric from './modules/Biometrics/EnableDisableBiometric';
import LoginWithBiometrics from './modules/Biometrics/LoginWithBiometrics';
import { useNetInfo } from "@react-native-community/netinfo";
import GlobalStyles from 'common/src/styles/GlobalStyles';
import CommonErrorBoundary from 'common/src/components/CommonErrorBoundary';
import { IS_ANALYTICS_ENABLED, IS_CRASH_REPORTS_ENABLED } from 'common/src/env.json';

import { RootState } from './store/reducers';

const Stack = createStackNavigator();

const rnBiometrics = new ReactNativeBiometrics({allowDeviceCredentials:true})

const config = {
  screens: {
    Tutorial: 'Tutorial',
    Mini_tutorial: 'Mini_tutorial',
    Welcome: 'Welcome',
    Login: 'Login',
    Register: 'Register',
    PasswordForgotten: 'PasswordForgotten',
    NeurologistStep: 'NeurologistStep',
    PrivacyConsent: 'PrivacyConsent',
    InformedConsent: 'InformedConsent',
    OnboardingQuestions: 'OnboardingQuestions',
    Settings: 'Settings',
    Profile: 'Profile',
    Address: 'Address',
    About: 'About',
    My_neurologists: 'My_neurologists',
    AddNeurologist: 'AddNeurologist',
    SeizureVideos: 'SeizureVideos',
    Nightwatch: 'Nightwatch',
    ChangeMyPdw: 'ChangeMyPdw',
    Timezone: 'Timezone',
    MoodSleepNotifications: 'MoodSleepNotifications',
    AddEvent: 'AddEvent',
    ReminderView: 'ReminderView',
    AddMedication: 'AddMedication',
    AddTreatments: 'AddTreatments',
    Drugs: 'Drugs',
    MedicationHistory: 'MedicationHistory',
    EditMedication: 'EditMedication',
    OnboardingAddMedication: 'OnboardingAddMedication',
    Reports: 'Reports',
    GenerateReport: 'GenerateReport',
    ReportGenerated: 'ReportGenerated',
    QualityOfLife: 'QualityOfLife',
    ProgramOverview: 'ProgramOverview',
    EducationModule: 'EducationModule',
    EducationChapter: 'EducationChapter',
    ProgramAction: 'ProgramAction',
    QoLForm: 'QoLForm',
    ImagePage: 'ImagePage',
    MySeizuresDescription: 'MySeizuresDescription',
    AddContact: 'AddContact',
    MySeizureAntidote: 'MySeizureAntidote',
    MyAppointments: 'MyAppointments',
    MyTriggersModel: 'MyTriggersModel',
    AppointmentChecklist: 'AppointmentChecklist',
    MoreGraphs: 'MoreGraphs',
    EnableDisableBiometric : 'EnableDisableBiometric',
    LoginWithBiometrics: 'LoginWithBiometrics',
    Main: {
      screens: {
        Home: 'Home',
        Diary: 'Diary',
        Treatments: 'Treatments',
        Programs: 'Programs',
        Studies: 'Studies',
        Settings: 'LogInSettings',
      },
    },
  },
};
const linking = {
  prefixes: [''],
  config
};

const App = ({ FBtoken }) => {
  const navigationRef = useNavigationContainerRef();
  const route = useRef();
  const dispatch = useDispatch();
  const Me = useSelector((state:any) => state.getMe.response)
  const patientData = useSelector((state:any) => state.getMe.patient)
  const GetMeConsent = useSelector((state:any) => state.getMe.consent)
  const GetMeOnBoarding = useSelector((state:any) => state.getMe.onBoarding)
  const GetMeSettings = useSelector((state:any) => state.getMe.settings)
  const AllSet = useSelector((state:any) => state.allSetReducer.all_set)
  const errorMessages = useSelector((state:any) => state.modal.errors);
  const successMessages = useSelector((state:any) => state.modal.success);
  const [LoadingRes, setLoadingRes] = useState(true)
  const isBiometricKeyExists = useSelector((state: RootState) => state.biometricKeyExistsReducer.isBiometricKeyExists);
  const Notifications = useSelector((state: RootState) => state.getNotifications.notifications)
  const { t } = useTranslation();
  const [isAllSet, setAllSet] = useState<boolean>(false);
  const [registartionPageNotDone, setRegistrationPageNotDone] = useState<boolean>(false)
  const [registrationPageName, setRegistrationPageName] = useState<string>('')
  const [FBToken, setFBToken] = useState<string>('')
  const [deviceType, setDeviceType] = useState<string>('')
  const netInfo = useNetInfo();
  const [stackState, setStackState] = useState<string>('notStack');
  const [resultStorage, setResultStorage] = useState<any>();
  const [dataLoaded, setDataLoaded] = useState(true);

  errorHandler(route);

  useEffect(() => {
    if(Platform.OS !== 'web'){
      const enableDisableAnalyticsCrashes = async () => {
        await Analytics.setEnabled(IS_ANALYTICS_ENABLED);
        await Crashes.setEnabled(IS_CRASH_REPORTS_ENABLED);
      }
      enableDisableAnalyticsCrashes();
      biometricKey();
    }
  },[])

  useEffect(() => {
    notificationEmitter.on(NotificationTypes.Nigthwatch, (data) => {
      let obj = {
        from:
          DateTime.fromISO(data.createdAt).toFormat("yyyy-MM-dd") + "T00:00:00",
        to:
          DateTime.fromISO(data.createdAt).toFormat("yyyy-MM-dd") + "T23:59:59",
      };
      dispatch(getNightWatchEventsAction(obj));
      dispatch(getNotificationsAction());
    });

    notificationEmitter.on(NotificationTypes.All, () => {
      dispatch(getNotificationsAction());
    });

    notificationEmitter.on(NotificationTypes.New_program, (data) => {
      dispatch(addNotificationsAction({ data }))
      dispatch(getPrograms({
        callback: () => {
          dispatch(getPatientPrograms({}))
        }
      }))
    })
  }, []);

  useEffect(() => {
    if (dataLoaded)
      return
    setDataLoaded(true)
    if (!Notifications || Notifications.length === 0 || !patientData)
      return;
    for (let i = 0; Notifications[i]; i++) {
      const notification = Notifications[i];
      if (notification.type === 'form_to_fill' || notification.type === 'form_to_fill_reminder') {
        if (!patientData.forms || patientData.forms.length === 0 || !patientData.forms.find(item => item.form === notification.data.form?.id && item.send_date === notification.data.send_date)) {
          dispatch(getMeAction({
            errorCallback: () => {
              setRegistrationPageName('')
              setStackState(NavTypes.NotCompleted)
            }
          }))
          break;
        }
      }
    }
  }, [dataLoaded])

  useEffect(() => {
    if (Notifications && Notifications.find(item => item.type === 'form_to_fill' || item.type === 'form_to_fill_reminder'))
      setDataLoaded(false)
  }, [Notifications])


  useEffect(() => {
    if (Platform.OS !== 'web') {
      SplashScreen.hide();
      Orientation.lockToPortrait();
    }
  }, []);

  useEffect(() => {
    setAllSet(AllSet)
  }, [AllSet]);

  useEffect(() => {
    getAllKeys()
  }, [GetMeConsent, GetMeOnBoarding, resultStorage, netInfo.isConnected])

  const getAllKeys = async () => {
    const result: any = {}
    const keys = await AsyncStorage.getAllKeys();
    let update = false;
    for (const key of keys) {
      const val = await AsyncStorage.getItem(key);
      if (!resultStorage || resultStorage[key] !== val) {
        update = true;
        if (resultStorage && resultStorage.token) {
          resultStorage.token = val
        }
      }
      result[key] = val;
    }

    if (update)
      setResultStorage(result)

    if (resultStorage) {
      if (resultStorage.tutorialIsDone === null || resultStorage.tutorialIsDone === "" || resultStorage.tutorialIsDone === undefined) {
        setStackState(NavTypes.Tutorial)
        setLoadingRes(false)
      }
      else if (resultStorage.token === null || resultStorage.token === "" || resultStorage.token === undefined) {
        setStackState(NavTypes.NotCompleted)
        setLoadingRes(false)
      }
      else if (resultStorage.token !== null && resultStorage.token !== "" && resultStorage.token !== undefined) {
        if (netInfo.isConnected && !Me) {
          if (!LoadingRes)
            setLoadingRes(true)
          dispatch(getMeAction({
            callback: () => {
              setLoadingRes(false)
            },
            errorCallback: () => {
              setLoadingRes(false)
              setRegistrationPageName('')
              setStackState(NavTypes.NotCompleted)
            }
          }));
        }

        else if (Me) {
          if (!GetMeOnBoarding && !GetMeOnBoarding?.endOnboarding && !GetMeOnBoarding?.neurologistDone) {
            setRegistrationPageName('NeurologistStep')
            setRegistrationPageNotDone(true)
            setStackState(NavTypes.NotCompleted)
          }
          else if (!GetMeConsent) {
            setRegistrationPageName('PrivacyConsent')
            setRegistrationPageNotDone(true)
            setStackState(NavTypes.NotCompleted)
          }
          else if (GetMeOnBoarding && !GetMeOnBoarding?.endOnboarding && !GetMeOnBoarding?.questionsDone) {
            setRegistrationPageName('OnboardingQuestions')
            setRegistrationPageNotDone(true)
            setStackState(NavTypes.NotCompleted)
          }
          else if (GetMeOnBoarding && GetMeConsent && GetMeOnBoarding?.endOnboarding) {
            setRegistrationPageNotDone(false)
            setRegistrationPageName('')
            setStackState(NavTypes.Completed)
            sendFBToken()
          }
        } else {
          setRegistrationPageName('')
          setStackState(NavTypes.NotCompleted)
        }
      }
    }
  }


  const findMyFBTokenDevice = (device, fbtoken) => {
    return GetMeSettings.devices[device].find(token => token === fbtoken)
  }

  const sendFBToken = async () => {
    const token = await FBtoken();
    const device = Platform.OS === 'android' ? 'android' : Platform.OS === 'ios' ? 'ios' : '';

    setFBToken(token);
    if (Platform.OS === 'android')
      setDeviceType('android')
    else if (Platform.OS === 'ios')
      setDeviceType('ios')

    let dataToSend = {
      data: {
        settings: {
          device_type: device,
          device_token: token
        }
      }
    }

    if (Platform.OS !== 'web' && !findMyFBTokenDevice(device, token) && netInfo.isConnected) {
      dispatch(sendFbTokenAction(dataToSend))
    }
  }

  const biometricKey = () => {
    rnBiometrics.biometricKeysExist()
      .then((resultObject) => {
        const { keysExist } = resultObject

        if (keysExist) {
          dispatch(biometricKeyExistsAction({key:true}))
        }
      })
  }

  const closeSuccessModal = () => {
    dispatch(resetSuccessModalAction({ message: successMessages[0] }));
  }

  const closeErrorModal = () => {
    if (errorMessages[0] === 'expired_token')
      dispatch(logOutAction());
    dispatch(resetErrorModalAction({ message: errorMessages[0] }));
  }

  const errorModalBtn = [{
    type: 'primary',
    title: t('translation:modal.btn.close'),
    click: closeErrorModal
  }]

  const successModalBtn = [{
    type: 'primary',
    title: t('translation:modal.btn.close'),
    click: closeSuccessModal
  }]

  const checkIfMsgIsTranslated = (msg) => {
    if (msg.includes('translation:'))
      return false
    else
      return true
  }

  const initialState = {
    index: 0,
    routes: [
      {
        name: 'Main',
      },
    ],
  };

  if (LoadingRes)
    return <Loading netConnected={netInfo.isConnected} />
  else
    return (
      <>
        <NavigationContainer
          ref={navigationRef}
          initialState={initialState}
          linking={linking}
          theme={{
            ...DefaultTheme,
            colors: {
              ...DefaultTheme.colors,
              background: '#FFF',
              primary: GlobalStyles.helpi.primary,
            }
          }}
          onReady={() => {
            // @ts-ignore
            route.current = navigationRef?.getCurrentRoute()?.name;
          }}
          onStateChange={async () => {
            const previousRouteName = route.current;
            const currentRouteName = navigationRef?.getCurrentRoute()?.name;
            if (previousRouteName && currentRouteName && (previousRouteName !== currentRouteName) && Platform.OS !== 'web')
              Analytics.trackEvent(currentRouteName?.toString(), { Previous_page: previousRouteName })
            // Save the current route name for later comparison
            // @ts-ignore
            route.current = currentRouteName;
          }}
        >
          <StatusBar
            barStyle="dark-content"
            backgroundColor="#fff"
          />
          {!!errorMessages?.length &&
            <CommonModal
              testID="errorModal"
              type={'error'}
              title={t('translation:modal.titles.error')}
              text={t(errorMessages[0] === "expired_token" ? "translation:errors.expired_token" : `translation:${errorMessages[0]}`) || errorMessages[0]}
              buttons={errorModalBtn}
              onClose={closeErrorModal}
            />
          }
          {!!successMessages?.length &&
            <CommonModal
              testID="successModal"
              type={'info'}
              title={t('translation:modal.titles.success')}
              text={checkIfMsgIsTranslated(successMessages[0]) ? successMessages[0] : t(successMessages[0])}
              buttons={successModalBtn}
              onClose={closeSuccessModal}
            />
          }
          {netInfo.isConnected && stackState &&
            <CommonErrorBoundary navigation={route} user_id={Me?.result?.id} version={version}>
              <Stack.Navigator
                screenOptions={{
                  headerShown: false,
                  title: "Helpilepsy",
                }}
              >
                {(stackState === NavTypes.Tutorial) ? (
                  <>
                    <Stack.Screen name="Tutorial" component={Tutorial} initialParams={{ param: false }} />
                    <Stack.Screen name="Welcome" component={Welcome} />
                    <Stack.Screen name="Login" component={Login} />
                    <Stack.Screen name="PasswordForgotten" component={PasswordForgotten} />
                    <Stack.Screen name="Register" component={Register} />

                    <Stack.Screen name="NeurologistStep" component={NeurologistStep} />
                    <Stack.Screen name="PrivacyConsent" component={PrivacyConsent} initialParams={{ param: false }} />
                    <Stack.Screen name="OnboardingQuestions" component={OnboardingQuestions} />
                    <Stack.Screen name="Settings" component={Settings} />
                    <Stack.Screen name="About" component={About} />
                    <Stack.Screen name="DataSharing" component={DataSharing} />
                  </>
                ) : (stackState === NavTypes.NotCompleted) ? (
                  <>
                    {registartionPageNotDone === true ?
                      <>
                        <Stack.Screen name={registrationPageName}
                          component={
                            registrationPageName === "NeurologistStep" ? NeurologistStep : registrationPageName === "PrivacyConsent" ? PrivacyConsent : OnboardingQuestions
                          } initialParams={{ param: false }} />
                        <Stack.Screen name="Welcome" component={Welcome} />
                      </>
                      :
                      <>
                        <Stack.Screen name="Welcome" component={Welcome} />
                        {registrationPageName !== '' &&
                          <Stack.Screen name={registrationPageName}
                            component={
                              registrationPageName === "NeurologistStep" ? NeurologistStep : registrationPageName === "PrivacyConsent" ? PrivacyConsent : OnboardingQuestions
                            } initialParams={{ param: false }} />
                        }
                      </>
                    }

                    <Stack.Screen name="Login" component={Login} />
                    <Stack.Screen name="PasswordForgotten" component={PasswordForgotten} />
                    <Stack.Screen name="Register" component={Register} />
                    {registrationPageName !== "NeurologistStep" &&
                      <Stack.Screen name="NeurologistStep" component={NeurologistStep} />
                    }
                    {
                      registrationPageName !== "OnboardingQuestions" &&
                      <Stack.Screen name="OnboardingQuestions" component={OnboardingQuestions} />
                    }
                    {
                      registrationPageName !== "PrivacyConsent" &&
                      <Stack.Screen name="PrivacyConsent" component={PrivacyConsent} initialParams={{ param: false }} />
                    }
                    <Stack.Screen name="Settings" component={Settings} />
                    <Stack.Screen name="About" component={About} />
                    <Stack.Screen name="DataSharing" component={DataSharing} />
                    <Stack.Screen name="Tutorial" component={Tutorial} initialParams={{ param: true }} />
                  </>
                ) : (stackState === NavTypes.Completed) ? (
                  <>
                    {isAllSet ?
                      <>
                        <Stack.Screen name="OnboardingAddMedication" component={OnboardingAddMedication} />
                        <Stack.Screen name='Main' component={TabNavigation} initialParams={{ deviceType, FBToken }} />
                      </>
                      :
                      <>
                        {isBiometricKeyExists && Platform.OS !== 'web' &&
                          <Stack.Screen name="LoginWithBiometrics" component={LoginWithBiometrics} options={{gestureEnabled: false}} />
                        }
                        <Stack.Screen name='Main' component={TabNavigation} initialParams={{ deviceType, FBToken }} />
                        <Stack.Screen name="OnboardingAddMedication" component={OnboardingAddMedication} />
                      </>
                    }

                    <Stack.Screen name="Profile" component={Profile} />
                    <Stack.Screen name="Address" component={Address} />
                    <Stack.Screen name="ChangeMyPdw" component={ChangeMyPdw} />
                    <Stack.Screen name="SeizureVideos" component={SeizureVideos} />
                    <Stack.Screen name="About" component={About} />
                    <Stack.Screen name="InformedConsent" component={InformedConsent} initialParams={{ param: true }} />
                    <Stack.Screen name="My_neurologists" component={My_neurologists} />
                    <Stack.Screen name="AddNeurologist" component={AddNeurologist} />
                    <Stack.Screen name="Nightwatch" component={Nightwatch} />
                    <Stack.Screen name="Mini_tutorial" component={Mini_tutorial} initialParams={{ param: true }} />
                    <Stack.Screen name="Timezone" component={Timezone} />
                    <Stack.Screen name="MoodSleepNotifications" component={MoodSleepNotifications} />
                    <Stack.Screen name="AddEvent" component={AddEvent} />
                    <Stack.Screen name="StudyOverview" component={CommonStudyOverview} />
                    <Stack.Screen name="StudySection" component={CommonStudySection} />
                    <Stack.Screen name="StudyForm" component={CommonStudyForm} />
                    <Stack.Screen name="StudyDisclaimer" component={CommonStudyDisclaimer} />
                    <Stack.Screen name="StudyContacts" component={CommonStudyContacts} />
                    <Stack.Screen name="ReminderView" component={ReminderView} />
                    <Stack.Screen name="AddMedication" component={AddMedication} />
                    <Stack.Screen name="AddTreatments" component={AddTreatments} />
                    <Stack.Screen name="DataSharingSignedIn" component={DataSharingSignedIn} />
                    <Stack.Screen name="Drugs" component={Drugs} />
                    <Stack.Screen name="MedicationHistory" component={MedicationHistory} />
                    <Stack.Screen name="EditMedication" component={EditMedication} />
                    <Stack.Screen name="NightwatchEvent" component={NightwatchEvent} />
                    <Stack.Screen name="ProgramIntroduction" component={CommonProgramIntroduction} />
                    <Stack.Screen name="Reports" component={Reports} />
                    <Stack.Screen name="GenerateReport" component={GenerateReport} />
                    <Stack.Screen name="ReportGenerated" component={ReportGenerated} />
                    <Stack.Screen name="QualityOfLife" component={QualityOfLife} />
                    <Stack.Screen name="ProgramOverview" component={ProgramOverview} />
                    <Stack.Screen name="EducationModule" component={EducationModule} />
                    <Stack.Screen name="EducationChapter" component={EducationChapter} />
                    <Stack.Screen name="ProgramAction" component={ProgramAction} />
                    <Stack.Screen name="QoLForm" component={QoLForm} />
                    <Stack.Screen name="ImagePage" component={ImagePage} />
                    <Stack.Screen name="MySeizuresDescription" component={MySeizuresDescription} />
                    <Stack.Screen name="AddContact" component={AddContact} />
                    <Stack.Screen name="MySeizureAntidote" component={MySeizureAntidote} />
                    <Stack.Screen name="MyAppointments" component={MyAppointments} />
                    <Stack.Screen name="MyTriggersModel" component={MyTriggersModel} />
                    <Stack.Screen name="AppointmentChecklist" component={AppointmentChecklist} />
                    <Stack.Screen name="MoreGraphs" component={MoreGraphs} />
                    <Stack.Screen name="EnableDisableBiometric" component={EnableDisableBiometric} />
                  </>
                ) :
                  <>
                    <Stack.Screen name="Loading" component={Loading} />
                  </>
                }
              </Stack.Navigator>
            </CommonErrorBoundary>
          }
          {(!netInfo.isConnected || LoadingRes) &&
            <Loading netConnected={netInfo.isConnected} />
          }
        </NavigationContainer>
      </>
    );
};

export default App;
