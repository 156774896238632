import React from 'react';
import { View, StyleSheet} from 'react-native';
import CommonImage from './CommonImage';
import Text from './CommonCustomTxt';
import {useTranslation} from 'react-i18next';
import {widthPercentageToDP as PR} from '../styles/PixelRatio';

const CommonAboutFooter = () => {
  const {t} = useTranslation();
  return (
    <>
      <View style={styles.footerRow}>
        <CommonImage name={'ceIcon'} height={PR(35)} width={PR(49)}/>
        <CommonImage name={'manufacturerIcon'} height={PR(35)} width={PR(49)}/>
        <View style={styles.footerColumn}>
          <Text renderAsHtml={true} style={styles.footerTxt}>{t('translation:settings.about.cAddressV')}</Text>
        </View>
      </View>
    </>
  )
};

const styles = StyleSheet.create({
  footerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'space-around',
    width: '75%',
    marginTop: PR(20),
    marginBottom: PR(30)
  },
  footerColumn: {
    flexDirection: 'column',
  },
  footerTxt: {
    fontSize: PR(11),
    lineHeight: PR(16)
  }
});

export default CommonAboutFooter;